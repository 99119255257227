// call modules to allpages
$.getScript("modules/nav/module_nav.js");
$.getScript("modules/ieDetect/module_ieDetect.js");
$.getScript("modules/whatsappLink/whatsappLink.js");

// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});
$(window).on('hashchange', function () {
    initGettersAndSetters();
});

// general input masks
if (is.not.ie()) {
    $('.cepMask').mask("99999-999");
    $('.cpfMask').mask("999.999.999-99");
    $('.dataMask').mask("99/99/9999");
    $('.foneMask').focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if (phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');
}

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}

// components
if ($('.liSelect').length) {
    $('.liSelect select').on('focusin', function () {
        $(this).parent('.liSelect').addClass('is-active');
    });
    $('.liSelect select').on('focusout', function () {
        $(this).parent('.liSelect').removeClass('is-active');
    });
}

if ($('.js-numericSmallTag').length) {
    $('.js-numericSmallTag').each(function( index ) {
        var spanValue = $(this).text();
        var intValue = parseInt(spanValue);
        console.log(intValue);

        if (intValue > 999) {
            $('.js-numericSmallTag').text('99+');
        } else {
            $('.js-numericSmallTag').text(spanValue);
        }
    });
}
